<template>
  <div>
    <!-- 弹窗form -->
    <el-drawer
      custom-class="form_drawer"
      :title="drawerTitle"
      size="80%"
      :visible.sync="formShow"
      @closeDrawer="closeDrawer"
      :modal-append-to-body="false">
      <!-- 表单 -->
      <DrawerComponent :button="drawerButton" :loading="loading" :drawerConfig="drawerConfig"
                       @save="requestSave" @colse="formShow = false"></DrawerComponent>

      <div>
        <el-drawer
          custom-class="form_drawer"
          title="选择客户"
          size="80%"
          :visible.sync="drawerShowCustomer"
          :modal="false"
          :append-to-body="true">
          <div class="drawer-table">
            <TableComponent @searchData="searchCustomer"
                            :pagination="tablePaginationCustomer"
                            :templateData="tableDataCustomer"
                            :table-loading="tableLoadingCustomer"
                            :searchConfig="searchConfigCustomer"
                            :table-config="tableConfigCustomer"
            ></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect" :btnType="'primary'" :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowCustomer = false" :btnType="'danger'" :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>

      <!-- 弹窗商品列表 -->
      <div>
        <el-drawer
          custom-class="form_drawer"
          title="添加商品"
          size="80%"
          :visible.sync="drawerShowProduct"
          :modal="false"
          :append-to-body="true">
          <div class="drawer-table">
            <TableComponent @searchData="searchProduct"
                            :pagination="tablePaginationProduct"
                            :templateData="tableDataProduct"
                            :table-loading="tableLoadingProduct"
                            :searchConfig="searchConfigProduct"
                            :table-config="tableConfigProduct"
            ></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect" :btnType="'primary'" :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowProduct = false" :btnType="'danger'" :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
    </el-drawer>
    <!-- 表单 -->
    <DrawerComponent v-if="dmsId" :button="drawerButton" :loading="loading" :drawerConfig="drawerConfig"
                     @save="requestSave" @colse="formShow = false"></DrawerComponent>
  </div>

</template>

<script>
import buttonComponent from '../../../../compoment/button-component/button-component';
import TableComponent from '../../../../compoment/table-component/table-component.vue';
import DrawerComponent from '../../../../compoment/drawer-component/drawer-component';

export default {
  name: 'form-component',
  components: {
    TableComponent,
    DrawerComponent,
    buttonComponent,
  },
  props: ['dmsId', 'dmsCode'],
  data() {
    return {
      flag: 'N',
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      selectTableData: [], // table列表选中的数据
      loading: false,
      id: '',
      code: 'add',
      // ---------------------- 弹窗 - 合同新增表单配置
      formShow: false,
      drawerTitle: '新增',
      drawerButton: {
        show: true,
        options: [
          {
            text: '暂存',
            fn: () => {
              this.flag = 'Y';
              this.confirmSubmit();
            },
          },
        ],
      },
      drawerConfig: [ // 弹窗组件配置
        {
          title: '基本信息',
          type: 'form',
          ruleForm: { orderSourceType: 'back' },
          formConfig: { // 弹窗表单配置
            disabled: false,
            labelWidth: '160px',
            items: [[
              {
                label: '单据编号',
                key: 'billCode',
                disabled: true,
              },
              {
                label: '客户名称',
                key: 'customerName',
                valueType: 'iconInput',
                icon: 'el-icon-circle-plus-outline',
                fn: () => {
                  this.searchCustomer({});
                },
              }, {
                label: '负责人',
                key: 'responsibleName',
                disabled: true,
              }, {
                label: '所属组织',
                key: 'businessName',
                disabled: true,
              }, {
                label: '审批状态',
                key: 'marketHitInfluence',
                valueType: 'select',
                show: false,
              }, {
                label: '商品名称',
                key: 'productName',
                valueType: 'iconInput',
                icon: 'el-icon-circle-plus-outline',
                fn: () => {
                  this.searchProduct({});
                },
              }, {
                label: '商品编码',
                key: 'productCode',
                disabled: true,
              }, {
                label: '包装属性',
                key: 'office',
                disabled: true,
              }, {
                label: '规格',
                key: 'productSpec',
                disabled: true,
              }, {
                label: '商品数量',
                key: 'productNumber',
              }, {
                label: '联系方式',
                key: 'linkPhone',
              }, {
                label: '退换品种及数量',
                key: 'changeTypeNumber',
              }, {
                label: '退货原因',
                key: 'returnCause',
                valueType: 'textarea',
                maxlength: 2000,
                autosize: true,
                itemWidth: '100%',
              },
              {
                label: '商品批次',
                key: 'productBarch',
                valueType: 'textarea',
                maxlength: 2000,
                autosize: true,
                itemWidth: '100%',
              },
              {
                label: '备注',
                key: 'remarks',
                valueType: 'textarea',
                autosize: true,
                maxlength: 2000,
                itemWidth: '100%',
              },
              {
                label: '附件',
                key: 'attachmentList',
                itemWidth: '100%',
                valueType: 'inputUpload',
                uploadConfig: {
                  accept: 'pdf,docx,xlsx,ppt,png,jpg',
                },
              },
            ]],
          },
          rules: {
            customerName: [
              {
                required: true,
                message: '请选择客户',
                trigger: 'change',
              },
            ],
            productName: [
              {
                required: true,
                message: '请选择商品',
                trigger: 'change',
              },
            ],
            productNumber: [
              {
                required: true,
                message: '请输入商品数量',
                trigger: 'blue',
              },
            ],
            changeTypeNumber: [
              {
                required: true,
                message: '请输入退换品种及数量',
                trigger: 'blue',
              },
            ],
            returnCause: [{
              required: true,
              message: '请输入退货原因',
              trigger: 'blue',
            }],
          },
        },
      ],

      //  ----------------------- 弹窗 - 客户列表
      drawerShowCustomer: false,
      tableLoadingCustomer: false,
      tableDataCustomer: [],
      tableConfigCustomer: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '客户名称',
            prop: 'customerName',
          },
          {
            label: '客户编码',
            prop: 'customerCode',
          }, {
            label: '客户类型',
            prop: 'customerTypeName',
          },
        ],
      },
      searchConfigCustomer: {
        options: [
          {
            valueName: 'customerName',
            placeholder: '请输入客户名称',
          }, {
            valueName: 'customerCode',
            placeholder: '请输入客户编码',
          },
        ],
      },
      tablePaginationCustomer: {},

      //  ----------------------- 弹窗 - 商品列表配置
      drawerShowProduct: false,
      tableLoadingProduct: false,
      tableDataProduct: [{}],
      tableConfigProduct: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '商品编码',
            prop: 'materialCode',
          },
          {
            label: '商品名称',
            prop: 'materialName',
          }, {
            label: '包装属性',
            prop: 'ext3',
          },
        ],
      }, // 列表配置
      searchConfigProduct: {
        options: [
          {
            valueName: 'materialCode',
            placeholder: '请输入商品编号',
          }, {
            valueName: 'materialName',
            placeholder: '请输入商品名称',
          },
        ],
      },
      tablePaginationProduct: {},
    };
  },

  created() {
    this.initPageState();

    /**
     * 以下两行未了处理
     * bpm待办事项引入组件查询详情 （审批流）
     */
    this.code = this.dmsCode || this.code;
    this.id = this.dmsId || this.id;
  },

  watch: {
    id(val) {
      if (val) {
        this.searchDetail();
      }
    },
  },

  methods: {
    initPageState() {
      // const itemFormNames = ['marketHitInfluence', 'billCode'];
      const itemFormNames = ['marketHitInfluence'];

      if (this.drawerTitle === 'view') { // 设置查看显示的字段
        this.setFormItemValue(itemFormNames, 'show', true);
      } else {
        this.setFormItemValue(itemFormNames, 'show', false);
      }

      if (this.code !== 'add') { // 非新增查询详情
        this.searchDetail();
      } else {
        this.setPeople();
      }
    },

    /**
     * 设置负责人相关信息
     */
    setPeople() {
      this.setFormData(this.userInfo.fullName, 'responsibleName');
      this.setFormData(this.userInfo.userCode, 'responsibleCode');
      this.setFormData(this.userInfo.orgName, 'businessName');
    },

    /**
     * 设置禁用
     * @param state {boolean}
     */
    setDisabled(state) {
      this.$nextTick(() => {
        this.drawerConfig[0].formConfig.disabled = state;
        this.drawerButton.show = !state;
      });
    },

    /**
     * 查询详情
     */
    async searchDetail() {
      if (this.id) {
        const { result } = await this.$http.post('/dms/changeProduct/getDetailByIds', [this.id], {
          headers: {
            ContentType: 'application/json;charset=UTF-8',
          },
        });
        this.drawerConfig[0].ruleForm = { ...result[0] };
        this.setDisabled(this.code === 'view');
      }
    },
    /**
     * 查询商品列表
     * @param requestData {Object}
     * @param url {string}
     * @returns {Promise<void>}
     */
    async searchProduct(requestData) {
      this.tableLoadingProduct = true;
      const requestUrl = '/mdm/mdmMaterialExtController/pageList';
      const params = { pageSize: 10, ...requestData, excludeList: [] };
      const { result: { data, count } } = await this.$http.post(requestUrl, params);
      this.tableDataProduct = data;
      this.tableLoadingProduct = false;
      this.tablePaginationProduct.total = count;
      if (!this.drawerShowProduct) {
        this.showDrawer('drawerShowProduct');
      }
    },

    /**
     * 查询客户
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchCustomer(requestData) {
      this.tableLoadingCustomer = true;
      const params = { pageSize: 10, ...requestData };
      const { result: { data, count } } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/pageList', params,
      );
      this.tableDataCustomer = data;
      this.tableLoadingCustomer = false;
      this.tablePaginationCustomer.total = count;
      this.showDrawer('drawerShowCustomer');
    },

    /**
     * 选择列表数据
     * @param data {Array}
     * @returns {boolean}
     */
    selectData(data) {
      if (this.drawerShowCustomer) { // 客户
        this.selectTableData = data;
      } else { // 商品
        const obj = {};
        const selectTableData = [...this.selectTableData, ...data];
        this.selectTableData = selectTableData?.reduce((item, next) => {
          if (!obj[next.id]) {
            item.push(next);
            obj[next.id] = true;
          }
          return item;
        }, []);
      }
    },

    /**
     * 区分弹窗列表 - 处理对应的数据
     */
    beforeSaveSelect() {
      if (this.drawerShowCustomer === true && !this.selectMessage()) {
        this.saveSelectCustomer();
        this.closeDrawer('drawerShowCustomer');
      }
      if (this.drawerShowProduct === true && !this.selectMessage()) { // 商品
        this.saveProduct();
        this.closeDrawer('drawerShowProduct');
        return true;
      }
    },

    /**
     * 弹窗保存验证提示
     */
    selectMessage() {
      if (this.selectTableData.length > 1) {
        this.$message.error('只能选择一条数据');
        return true;
      }
      return false;
    },

    /**
     * 保存客户
     */
    saveSelectCustomer() {
      this.setFormData(this.selectTableData[0].customerCode, 'customerCode');
      this.setFormData(this.selectTableData[0].customerName, 'customerName');
    },

    /**
     * 保存商品
     */
    saveProduct() {
      console.log(this.selectTableData);
      this.setFormData(this.selectTableData[0].materialName, 'productName');
      this.setFormData(this.selectTableData[0].materialCode, 'productCode');
      this.setFormData(this.selectTableData[0].ext3, 'office');
      this.setFormData(this.selectTableData[0].specification, 'productSpec');
    },

    /**
     * 提交表单数据
     * @param data {Object}
     */
    async requestSave() {
      this.flag = 'N';
      this.confirmSubmit();
    },

    // 确认提交
    async confirmSubmit() {
      this.loading = true;
      const data = this.drawerConfig[0].ruleForm;
      const url = this.code === 'edit' ? '/dms/changeProduct/updateChangeProduct' : '/dms/changeProduct/saveChangeProduct';
      data.temporaryFlag = this.flag;
      try {
        await this.$http.post(url, data);
        this.$message.success('保存成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable();
      } catch (e) {
        this.loading = false;
      }
    },

    /**
     * 设置表单数据
     * @param data {}
     * @param dataName {string}
     */
    setFormData(data, dataName) {
      this.$set(this.drawerConfig[0].ruleForm, dataName, data);
    },

    /**
     * 显示列表弹窗 清楚选中的数据
     * @param name {string}
     */
    showDrawer(name) {
      this[name] = true;
      this.selectTableData = [];
    },

    /**
     * 显示表单部分字段
     * @param names {Array<string>}
     * @param state {boolean}
     */
    setFormItemValue(names, keyName, data) {
      this.drawerConfig.forEach((k) => {
        if (k.type === 'form') {
          k.formConfig.items[0].map((i) => {
            if (names.includes(i.key)) {
              if (i[keyName]) {
                i[keyName] = data;
              } else {
                this.$set(i, keyName, data);
              }
              return i;
            }
            return i;
          });
        }
      });
    },

    /**
     * 关闭弹窗
     * @param name {string}
     */
    closeDrawer(name) {
      this.selectTableData = [];
      this[name] = false;
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-button {
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #EBEEF5;
}

.drawer-table {
  padding: 20px;
  margin-bottom: 100px;
}

.slot-body {
  display: flex;

  div {
    display: flex;
    align-items: center;
    flex-grow: 2;

    span {
      flex-shrink: 0;
      margin-right: 5px;
    }

    input {
      width: 150px;
    }
  }
}
</style>
